import type { BlockTypes } from '@etf1-interne/tf1info_types_news'

import { useState } from 'react'
import { PictureElementList } from '../../../atoms/PictureElementList'
import { mixins, theme } from '../../../../styles'
import { DurationLabel } from '../../../atoms/DurationLabel'
import { PreviewVideo } from '../../../atoms/PreviewVideo'
import { CarouselItemTitle } from '../components/CarouselItemTitle'
import { CarouselItemDate } from '../components/CarouselItemDate'
import { CarouselItemVideoIndicator } from '../components/CarouselItemVideoIndicator'
import { animatedVars } from '../../../molecules/VideoIndicator'

function ArticleContent({
  pictures,
  duration,
  icon,
  link,
  title,
  date,
  hasMultiLive,
  onClick,
  previewVideoUrl,
}: Pick<
  BlockTypes['carousel-highlights']['data']['elementList'][number],
  'pictures' | 'duration' | 'icon' | 'link' | 'title' | 'date' | 'hasMultiLive' | 'previewVideoUrl'
> & {
  onClick: () => void
}) {
  const [isMouseOver, setIsMouseOver] = useState(false)
  return (
    <>
      <div
        className="CarouselCardItem__Content flex flex-column justify-end"
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <CarouselItemTitle
          title={title}
          link={link}
          onClick={onClick}
          icon={icon}
          hasMultiLive={hasMultiLive}
        />
        {duration ? (
          <DurationLabel className="CarouselCardItem__Duration" duration={duration} />
        ) : null}
        <CarouselItemDate date={date} icon={icon} />
      </div>
      {previewVideoUrl ? (
        <PreviewVideo
          url={previewVideoUrl}
          pictures={pictures}
          withIcon={false}
          isActive={isMouseOver}
        />
      ) : (
        <PictureElementList {...pictures} aria-hidden={true} />
      )}
      <CarouselItemVideoIndicator icon={icon} />

      <style jsx>{`
        .CarouselCardItem__Content {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          padding: 20px;
          gap: 12px;
          background: linear-gradient(180deg, rgba(15, 19, 55, 0.4) 15%, rgba(15, 19, 55, 0) 55%),
            linear-gradient(180deg, rgba(15, 19, 55, 0.04) 10%, rgba(15, 19, 55, 0.4) 60%);
          z-index: 1;
        }

        .CarouselCardItem__Content :global(h2) {
          order: 2;
        }

        .CarouselCardItem__Content :global(.CarouselCardItem__Duration) {
          align-self: start;
          order: 1;
        }

        .CarouselCardItem__Content :global(.CarouselItemDate) {
          order: 3;
        }
      `}</style>
    </>
  )
}

export function CarouselCardItem({
  title,
  pictures,
  duration,
  icon,
  link,
  date,
  hasMultiLive,
  onClick,
  previewVideoUrl,
}: BlockTypes['carousel-highlights']['data']['elementList'][number] & {
  onClick: () => void
}): JSX.Element {
  return (
    <>
      <li className="CarouselCardItem">
        <article className="CarouselCardItem__Item">
          {title ? (
            <ArticleContent
              pictures={pictures}
              duration={duration}
              icon={icon}
              link={link}
              title={title}
              date={date}
              hasMultiLive={hasMultiLive}
              onClick={onClick}
              previewVideoUrl={previewVideoUrl}
            />
          ) : (
            <a className="CarouselCardItem__Link" href={link} onClick={onClick}>
              <PictureElementList {...pictures} />
            </a>
          )}
        </article>
      </li>

      <style jsx>{`
        .CarouselCardItem:hover :global(svg.CarouselItemVideoIndicator) {
          ${animatedVars}
        }
      `}</style>

      <style jsx>{`
        .CarouselCardItem {
          position: relative;
          width: 260px;
          min-width: 260px;
          max-width: 260px;
          height: 325px;
          min-height: 325px;
          overflow: hidden;
          border-radius: 4px;
        }

        .CarouselCardItem__Item {
          border-radius: 4px;
          overflow: hidden;
          height: 100%;
          width: 100%;
        }

        .CarouselCardItem:has(a:focus) {
          outline: 5px solid ${theme.cssVars.focusBlue};
        }

        .CarouselCardItem .CarouselCardItem__Link:before,
        .CarouselCardItem :global(.CarouselItemTitle__Link:before) {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }

        .CarouselCardItem .CarouselCardItem__Link,
        .CarouselCardItem :global(.CarouselItemTitle__Link) {
          outline: none;
        }

        .CarouselCardItem :global(img) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        @media ${mixins.mediaQuery.desktop} {
          .CarouselCardItem {
            width: 373px;
            min-width: 373px;
            max-width: 373px;
            height: 466px;
            min-height: 466px;
          }
        }
      `}</style>
    </>
  )
}
