import { SVGIcon } from '../../../atoms/SVGIcon'
import { Time } from '../../../atoms/Time'
import { mixins, theme } from '../../../../styles'

export function CarouselItemDate({ date, icon }) {
  if (!date) return null
  return (
    <>
      <div className="CarouselItemDate flex items-center">
        {icon === 'article-video' ? (
          <SVGIcon name="play" size="16px" primaryColor={theme.cssVars.white} />
        ) : null}

        <time dateTime={date} className="CarouselItemDate__Time">
          <Time publishedAt={date} />
        </time>
      </div>

      <style jsx>{`
        .CarouselItemDate {
          gap: 8px;
        }

        .CarouselItemDate__Time {
          color: ${theme.cssVars.white};
          font-size: 11px;
          font-weight: 400;
          line-height: 133%;
          margin: 0;
          opacity: 0.6;
          padding-top: 2px;
        }

        @media ${mixins.mediaQuery.desktop} {
          .CarouselItemDate__Time {
            font-size: 14px;
          }
        }
      `}</style>
    </>
  )
}
