import { useEffect, useRef, useState } from 'react'
import { isServer } from '../../../helpers/client'
import { useViewport } from '../../../hook/useViewport'

export function useCarouselNavigation({
  container,
  scaleAnimationEnabled,
}: {
  container: HTMLElement
  scaleAnimationEnabled: boolean
}) {
  const { isDesktop, viewport } = useViewport()
  const isScrolling = useRef(null)
  const [isFirstElementVisible, setIsFirstElementVisible] = useState(true)
  const [isLastItemVisible, setIsLastItemVisible] = useState(false)

  const isContainerReady = !!container

  function handleScroll() {
    // Update the state of the first and last elements visibility
    clearTimeout(isScrolling.current)
    isScrolling.current = setTimeout(() => {
      setIsFirstElementVisible(container.scrollLeft < container.querySelector('li')?.clientWidth)
      setIsLastItemVisible(container.scrollLeft + container.clientWidth === container.scrollWidth)
    }, 100)

    // Handle the scale animation of the carousel items
    if (!isDesktop && scaleAnimationEnabled) {
      const items = container.querySelectorAll('article')

      const scrollLeft = container.scrollLeft
      const containerWidth = container.scrollWidth
      const itemWidth = containerWidth / items.length

      if (itemWidth) {
        items.forEach((item, index) => {
          const startPosition = (index - 1) * itemWidth
          const endPosition = (index + 1) * itemWidth

          // The peak range is the range where the item is at its maximum scale
          // It should work like this:
          // | scale 0.9          | scale 0.9 to 1       | scale 1                        | scale 1 to 0.9 | scale 0.9 |
          // | item with index - 2| item with index - 1  | current index + 1/4 item width | index + 1      | index + 2 |
          const peakPosition = index * itemWidth
          const peakStartPosition = peakPosition
          const peakEndPosition = peakPosition + itemWidth / 4

          // If the the item is not in the viewport, scale it down
          if (scrollLeft < startPosition || scrollLeft > endPosition) {
            item.style.transform = 'scale(0.9)'
            return
          }

          // If the item is in the peak range, scale it to 1
          if (scrollLeft >= peakStartPosition && scrollLeft <= peakEndPosition) {
            item.style.transform = 'scale(1)'
            return
          }

          // The item appears/disappears from the left , scale it up/down
          if (scrollLeft >= startPosition && scrollLeft < peakStartPosition) {
            // 1 (max scale) - 0.1 (max scale decrease) * ((peakStartPosition - scrollLeft) / itemWidth) (percentage of the item in the peak range)
            item.style.transform = `scale(${
              1 - ((peakStartPosition - scrollLeft) / itemWidth) * 0.1
            })`
            return
          }

          // The item appears/disappears from the right , scale it up/down
          if (scrollLeft >= peakEndPosition && scrollLeft < endPosition) {
            // 1 (max scale) - 0.1 (max scale decrease) * ((scrollLeft - peakEndPosition) / itemWidth) (percentage of the item in the peak range)
            item.style.transform = `scale(${
              1 - ((scrollLeft - peakEndPosition) / itemWidth) * 0.1
            })`
            return
          }
        })
      }
    }
  }

  useEffect(() => {
    if (isServer() || !container || !viewport) return
    // If the viewport is desktop, we don't animate the carousel items
    if (!isDesktop) {
      // Initialize the item sizes
      handleScroll()
    }
    container.addEventListener('scroll', handleScroll, { passive: true })

    return function cleanup() {
      clearTimeout(isScrolling.current)
      container.removeEventListener('scroll', handleScroll)
    }
  }, [isContainerReady, isDesktop, viewport])

  function goToNextSlide() {
    container.scrollLeft += container.querySelector('li')?.clientWidth || 0
  }

  function goToPrevSlide() {
    container.scrollLeft -= container.querySelector('li')?.clientWidth || 0
  }

  return {
    isFirstElementVisible,
    isLastItemVisible,
    goToNextSlide,
    goToPrevSlide,
  }
}
