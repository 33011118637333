import { VideoIndicator } from '../../../molecules/VideoIndicator'

export function CarouselItemVideoIndicator({ icon }: { icon: string }): JSX.Element | null {
  if (icon !== 'video') {
    return null
  }

  return (
    <>
      <VideoIndicator size="50px" className="CarouselItemVideoIndicator" />
      <style global jsx>{`
        svg.CarouselItemVideoIndicator {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      `}</style>
    </>
  )
}
