function removeTrailingSlash(path: string): string {
  if (path.endsWith('/')) {
    return path.slice(0, -1)
  }
  return path
}

function removeFirstSlash(path: string): string {
  if (path.startsWith('/')) {
    return path.replace('/', '')
  }
  return path
}

export function clearSlash(path: string): string {
  if (!path) return path
  return removeTrailingSlash(removeFirstSlash(path))
}
