import type { BlockTypes } from '@etf1-interne/tf1info_types_news'

import { useState } from 'react'
import { PictureElementList } from '../../../atoms/PictureElementList'
import { mixins, theme } from '../../../../styles'
import { DurationLabel } from '../../../atoms/DurationLabel'
import { PreviewVideo } from '../../../atoms/PreviewVideo'
import { CarouselItemTitle } from '../components/CarouselItemTitle'
import { CarouselItemDate } from '../components/CarouselItemDate'
import { CarouselItemVideoIndicator } from '../components/CarouselItemVideoIndicator'
import { animatedVars } from '../../../molecules/VideoIndicator'

export function CarouselThumbnailItem({
  title,
  pictures,
  duration,
  icon,
  link,
  date,
  hasMultiLive,
  onClick,
  previewVideoUrl,
}: BlockTypes['carousel-highlights']['data']['elementList'][number] & {
  onClick: () => void
}): JSX.Element {
  const [isMouseOver, setIsMouseOver] = useState(false)
  return (
    <>
      <li className="CarouselThumbnailItem">
        <article
          className="CarouselThumbnailItem__Item flex flex-column-reverse"
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
        >
          <div className="CarouselThumbnailItem__Content flex flex-column">
            <CarouselItemTitle
              title={title}
              link={link}
              onClick={onClick}
              icon={icon}
              hasMultiLive={hasMultiLive}
            />
            <CarouselItemDate date={date} icon={icon} />
          </div>
          <div className="CarouselThumbnailItem__Header">
            {previewVideoUrl ? (
              <PreviewVideo
                url={previewVideoUrl}
                pictures={pictures}
                withIcon={false}
                isActive={isMouseOver}
              />
            ) : (
              <PictureElementList {...pictures} aria-hidden={true} />
            )}
            {duration ? (
              <DurationLabel className="CarouselThumbnailItem__Duration" duration={duration} />
            ) : null}
            <CarouselItemVideoIndicator icon={icon} />
          </div>
        </article>
      </li>

      <style jsx>{`
        .CarouselThumbnailItem:hover :global(svg.CarouselItemVideoIndicator) {
          ${animatedVars}
        }
      `}</style>

      <style jsx>{`
        .CarouselThumbnailItem {
          position: relative;
          width: 260px;
          min-width: 260px;
          max-width: 260px;
          height: 100%;
        }

        .CarouselThumbnailItem__Item {
          height: 100%;
          width: 100%;
        }

        .CarouselThumbnailItem__Header {
          position: relative;
          height: 146px;
          min-height: 146px;
          max-height: 146px;
          width: 100%;
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 12px;
        }

        .CarouselThumbnailItem :global(img) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .CarouselThumbnailItem :global(.CarouselThumbnailItem__Duration) {
          position: absolute;
          bottom: 5px;
          left: 5px;
        }

        .CarouselThumbnailItem__Content {
          gap: 8px;
        }

        .CarouselThumbnailItem__Content :global(.CarouselItemTitle) {
          color: ${theme.cssVars.fontColor};
        }

        .CarouselThumbnailItem__Content :global(.CarouselItemDate__Time) {
          color: ${theme.cssVars.fontColor};
        }

        .CarouselThumbnailItem :global(.CarouselItemDate svg) {
          --svg-color-primary: ${theme.cssVars.fontColor};
        }

        .CarouselThumbnailItem:has(a:focus) {
          outline: 5px solid ${theme.cssVars.focusBlue};
        }

        .CarouselThumbnailItem :global(.CarouselItemTitle__Link:before) {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }

        .CarouselThumbnailItem :global(.CarouselItemTitle__Link) {
          outline: none;
        }

        @media ${mixins.mediaQuery.desktop} {
          .CarouselThumbnailItem {
            width: 373px;
            min-width: 373px;
            max-width: 373px;
          }

          .CarouselThumbnailItem__Header {
            height: 210px;
            min-height: 210px;
            max-height: 210px;
          }
        }
      `}</style>
    </>
  )
}
