import { SVGIcon } from '../../../atoms/SVGIcon'
import { theme } from '../../../../styles'

export function CarouselItemTitle({ title, icon, link, hasMultiLive, onClick }) {
  return (
    <>
      <h2 className="CarouselItemTitle">
        {hasMultiLive ? <SVGIcon name="direct" size="20px" /> : null}
        <a
          className="CarouselItemTitle__Link"
          href={link}
          onClick={onClick}
          title={title}
          aria-label={title}
        >
          {icon && <span className="sr-only">[Vidéo]</span>}
          {title}
        </a>
      </h2>

      <style jsx>{`
        .CarouselItemTitle {
          color: ${theme.cssVars.white};
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          font-size: 20px;
          font-weight: 700;
          line-height: 133%;
          margin: 0;
        }

        .CarouselItemTitle :global(svg) {
          margin-right: 4px;
          margin-bottom: -3px;
        }
      `}</style>
    </>
  )
}
